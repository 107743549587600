import React, { useEffect, useState } from "react";
import MagicMatchesSignUpForm from "./MagicMatchesSignup.jsx";
import { gql } from "@apollo/client";
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap";
import Switch from "react-switch";
import NumberOfItemsPerMonth from "./NumberOfItemsPerMonth.jsx";
import { initializeApollo } from "src/helpers/apollo.js";
import ConfirmationModal from "../modals/ConfirmationModal.jsx";
import { toast } from "react-toastify";

const UPDATE_MAGIC_MATCHES_PREFERENCES = gql`
  mutation adminUpdateMagicMatchesPreferences($input: UpdateMagicMatchesPreferencesInput!) {
    adminUpdateMagicMatchesPreferences(input: $input) {
      enabled
      numberOfItemsPerMonth
      createdAt
      updatedAt
      uuid
    }
  }
`;

const MagicMatchesPreferences = ({ nonprofit }) => {
  const [magicMatchesPreferences, setMagicMatchesPreferences] = useState();
  const [updatingPreferences, setUpdatingPreferences] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState(50);

  const client = initializeApollo();
  const updateMagicMatchesPreferences = (vals) => {
    client
      .mutate({
        mutation: UPDATE_MAGIC_MATCHES_PREFERENCES,
        variables: {
          input: {
            enabled: vals?.enabled,
            numberOfItemsPerMonth: vals?.numberOfItemsPerMonth,
            nonprofitUUID: nonprofit?.uuid,
          },
        },
      })
      .then((r) => {
        setMagicMatchesPreferences(r?.data?.adminUpdateMagicMatchesPreferences);
        setEnabled(r?.data?.adminUpdateMagicMatchesPreferences?.enabled);
        setNumberOfItems(
          r?.data?.adminUpdateMagicMatchesPreferences?.numberOfItemsPerMonth,
        );
        toast.success("Successfully updated magic matches preferences");
        setUpdatingPreferences(false);
        setOpenConfirmationModal(false);
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  useEffect(() => {
    setMagicMatchesPreferences(nonprofit?.magicMatchesPreferences);
    setEnabled(nonprofit?.magicMatchesPreferences?.enabled);
    setNumberOfItems(nonprofit?.magicMatchesPreferences?.numberOfItemsPerMonth);
  }, [nonprofit]);

  const getConfirmationTitle = () => {
    return magicMatchesPreferences?.enabled
      ? "Opt-out of Magic Matches"
      : "Opt-in to Magic Matches";
  };

  const getConfirmationDesc = () => {
    return magicMatchesPreferences?.enabled
      ? "Are you sure you want to opt out of Magic Matches?"
      : "Are you sure you want to opt in to Magic Matches?";
  };

  return (
    <>
      {!magicMatchesPreferences && (
        <>
          <h4>Magic Matches is disabled for {nonprofit?.organizationName}</h4>
          <MagicMatchesSignUpForm onSubmit={updateMagicMatchesPreferences} />
        </>
      )}
      {magicMatchesPreferences && (
        <Container>
          <Card
            className="my-4"
            style={{ borderRadius: "0.5rem", padding: "1rem" }}
          >
            <CardBody>
              <Row>
                <Col
                  xs="12"
                  md="8"
                >
                  <h5
                    className="py-2"
                    style={{ color: enabled ? "black" : "gray" }}
                  >
                    Magic Matches is {enabled ? "enabled" : "disabled"} for{" "}
                    {nonprofit?.organizationName}
                  </h5>
                </Col>
                <Col
                  xs="12"
                  md="4"
                  className="d-flex justify-content-end"
                >
                  <Switch
                    checked={enabled}
                    onChange={() => setOpenConfirmationModal(true)}
                    onColor="#cee0e1"
                    onHandleColor="#0b6568"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={25}
                    width={60}
                    id="magic-matches-enabled-switch"
                  />
                  <ConfirmationModal
                    loading={updatingPreferences}
                    title={getConfirmationTitle()}
                    desc={getConfirmationDesc()}
                    open={!!openConfirmationModal}
                    onClose={() => setOpenConfirmationModal(false)}
                    onConfirm={() => {
                      setUpdatingPreferences(true);
                      updateMagicMatchesPreferences({
                        ...magicMatchesPreferences,
                        enabled: !enabled,
                      });
                    }}
                  />
                </Col>
              </Row>
              {enabled && (
                <>
                  <Row className="mt-5">
                    <Col className="text-center justify-items-center">
                      <NumberOfItemsPerMonth
                        numberOfItems={numberOfItems}
                        setNumberOfItems={setNumberOfItems}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        onClick={() => {
                          setUpdatingPreferences(true);
                          updateMagicMatchesPreferences(
                            {
                              ...magicMatchesPreferences,
                              numberOfItemsPerMonth: numberOfItems,
                              enabled: enabled,
                            },
                            () => {
                              setMagicMatchesPreferences((p) => ({
                                ...p,
                                numberOfItemsPerMonth: numberOfItems,
                              }));
                              setUpdatingPreferences(false);
                              toast.success("Updated Magic Match preferences");
                            },
                          );
                        }}
                        disabled={
                          numberOfItems ===
                          magicMatchesPreferences?.numberOfItemsPerMonth
                        }
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  );
};

export default MagicMatchesPreferences;
