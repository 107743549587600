import React from "react";

const UUID = ({ uuid, name }) => {
  const handleIdNavigate = () => {
    window.open(window.location.origin + "/nonprofit/" + uuid);
  };

  return (
    <p
      onClick={handleIdNavigate}
      style={{ color: "tomato", letterSpacing: -0.1 }}
      className="cursor-pointer"
      id={`uuid-${uuid}`}
    >
      {name}
    </p>
  );
};

const nonprofitsFormatter = (nonprofits) => {
  if (!nonprofits?.length) {
    return;
  }

  const formattedNonprofits = nonprofits.map((d) => {
    const n = {
      organizationName: (
        <UUID
          uuid={d?.uuid}
          name={d?.organizationName}
        />
      ),
      numberOfOrders: d?.numberOfOrders,
      verifiedUsers: d?.verifiedUsers,
      ein: d?.ein,
      businessPhone: d?.businessPhone,
      "Category Types": d?.categoryTypes ? (
        <div className="d-flex flex-wrap mt-1 mb-1">
          {d.categoryTypes.map((d, i) => (
            <div
              key={i}
              style={{
                padding: "1px 3px",
                background: "#ffc1c1",
                textAlign: "center",
                width: "fit-content",
                margin: "1px 1px 1px 0px",
                fontSize: "12px",
                borderRadius: "4px",
                textTransform: "capitalize",
                letterSpacing: "-0.5px",
              }}
            >
              {d.split("_").join(" ")}
            </div>
          ))}
        </div>
      ) : (
        "-"
      ),
      city: `${d?.address?.city}, ${d?.address?.state}`,
      uuid: d.uuid,
    };

    if (d?.distanceMiles !== null) {
      n["distanceMiles"] = d.distanceMiles;
    }
    return n;
  });
  return formattedNonprofits;
};

export default nonprofitsFormatter;
