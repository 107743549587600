import React, { Fragment, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { gql } from "@apollo/client";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { initializeApollo } from "../../../helpers/apollo";
import Breadcrumb from "../../common/breadcrumb";
import AddEditProduct from "./AddEditProduct";
import { err_toast } from "src/helpers/errors";

export const EDIT_PRODUCT = gql`
  mutation adminEditProduct($data: EditProductInput!) {
    adminEditProduct(input: $data) {
      uuid
      title
      description
      images {
        resolutions {
          m {
            url
          }
        }
      }
    }
  }
`;

const GET_PRODUCT = gql`
  query product($input: GetProductInput!) {
    product(input: $input) {
      uuid
      sku
      productClass {
        uuid
      }
      title
      description
      nextPostingDate
      fairMarketValue {
        value
        currencyCode
      }
      isBundle
      autoMatch
      productBundles {
        quantity
        product {
          uuid
          title
        }
      }
      notes {
        uuid
        createdDate
        objectType
        objectUUID
        user {
          firstName
          lastName
        }
        text
      }
      donorRetailer {
        uuid
        name
      }
      donor {
        uuid
        firstName
        lastName
        isGuest
      }
      pickupHours{
        intervals{
          days,
          startTimeLocal,
          endTimeLocal
        }
      }
      store {
        name
        uuid
        businessAddress {
          streetAddress1
          streetAddress2
          streetAddress3
          streetAddress4
          city
          countryCode
          state
          zip
        }
        createdDate
        businessPhoneNumber
        description
        locations {
          address {
            streetAddress1
            streetAddress2
            streetAddress3
            streetAddress4
            city
            countryCode
            state
            zip
            timezone
          }
        }
      }
      lengthInches
      widthInches
      heightInches
      weightLbs
      isNonprofitGift
      noResale
      categories {
        name
        description
        subCategories {
          name
          description
        }
      }
      recipientTypes
      quantity
      quantityPalletsPerTruckload
      quantityBoxPerPallet
      quantityIndividualItemPerBox
      availableQuantity
      conditionTagTypes
      conditionTags {
        label
        type
      }
      fulfillmentTypes
      visibility
      categories {
        name
        type
        description
        images {
          alt
          uuid
        }
      }
      images {
        url
        uuid
        alt
        displayOrder
        resolutions {
          m {
            url
            height
            width
            size
          } 
          xl {
            url
            height
            width
            size
          }
        }
      }
      location {
        address {
          streetAddress1
          streetAddress2
          streetAddress3
          streetAddress4
          state
          zip
          city
          countryCode
          latitude
          longitude
          timezone
        }
        contactNumber
        contactEmail
        notes
      }
      status
      unitType
      createdDate
      distanceMiles
      customRadiusInMiles
      minimumOrderQuantity
    }
  }
`;

const EditProduct = ({ match }) => {
  const uuid = match.params.uuid;

  const [data, setData] = useState({});

  const history = useHistory();

  const handleEditProduct = (vals, cb) => {
    const newValues = { ...vals };

    let client = initializeApollo();

    const obj = {
      uuid,
      product: newValues,
    };

    client
      .mutate({
        mutation: EDIT_PRODUCT,
        variables: { data: obj },
      })
      .then((_) => {
        toast.success("Product edited successfully!");
        setTimeout(() => {
          history.replace("/product/" + uuid);
          cb();
        }, 1000);
      })
      .catch((err) => {
        err_toast(err);
        cb();
      });
  };

  useEffect(() => {
    const client = initializeApollo();

    client
      .query({
        query: GET_PRODUCT,
        variables: { input: { uuidOrSlug: uuid } },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setData(res.data.product);
      });
  }, [uuid]);

  return (
    <Fragment>
      <Breadcrumb
        title="Edit Product"
        parent="Physical"
      />
      <Container fluid={true}>
        <AddEditProduct
          data={data}
          useRecentLocationInfo={false}
          onClose={() => history.push("/product/" + uuid)}
          onSubmit={handleEditProduct}
          autoSuggestStockImage={false}
          isDonation={false}
        />
      </Container>
    </Fragment>
  );
};

export default EditProduct;
