import { gql, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import Bold from "../common/bold";
import Text from "../common/text";
import moment from "moment";
import ActivatePricing from "./activatePricing";
import AddPricing from "./addPricing";

const ADMIN_GET_ALL_PRICING = gql`
    query adminGetAllPricing($retailerUUID: String!) {
        adminGetAllPricing(retailerUUID: $retailerUUID) {
            id
            type
            status
            createdDate
            individualItemPrice {
                value
                currencyCode
            }
            largeItemPrice {
                value
                currencyCode
            }
            boxPrice {
                value
                currencyCode
            }
            oversizedItemPrice{
              value
              currencyCode
            }
            palletPrice {
                value
                currencyCode
            }
            largePalletPrice {
              value
              currencyCode
            }
            xlPalletPrice {
              value
              currencyCode
            }
            xxlPalletPrice {
              value
              currencyCode
            }
            shippingPrice {
                value
                currencyCode
            }
            individualItemDeliveryPrice {
              value
              currencyCode
            }
            boxDeliveryPrice {
              value
              currencyCode
            }
            largeItemDeliveryPrice {
              value
              currencyCode
            }
            oversizedItemDeliveryPrice {
              value
              currencyCode
            }
            palletDeliveryPrice {
              value
              currencyCode
            }
            largePalletDeliveryPrice {
              value
              currencyCode
            }
            xlPalletDeliveryPrice {
              value
              currencyCode
            }
            xxlPalletDeliveryPrice {
              value
              currencyCode
            }
        }
    }
`;

const Pricing = ({ retailer }) => {
  const [adminGetAllPricing, { loading, data }] = useLazyQuery(
    ADMIN_GET_ALL_PRICING,
    {
      fetchPolicy: "no-cache",
      variables: {
        retailerUUID: retailer?.uuid,
      },
    },
  );

  const [pricings, setPricings] = useState([]);

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetAllPricing) {
        setPricings(data.adminGetAllPricing);
      }
    } else {
      setPricings([]);
    }
  }, [loading]);

  useEffect(() => {
    if (!retailer || !retailer?.uuid) {
      return;
    }

    adminGetAllPricing();
  }, [retailer]);

  const displayPrice = (p) => (p ? `$${p?.value}` : `-`);

  return (
    <>
      <AddPricing retailer={retailer} />
      {pricings.map((pricing) => (
        <Card
          key={pricing.id}
          style={{
            background: pricing.status === "active" ? "white" : "silver",
            boxShadow: "1px 4px 14px rgba(60, 72, 72, 0.4)",
          }}
        >
          <CardBody>
            <Row>
              <Col>
                <Bold>Created Date</Bold>
                <Text>
                  {moment(pricing.createdDate).format("YYYY-MM-DD HH:mm:SS")}
                </Text>
              </Col>
              <Col>
                <Bold>Type</Bold>
                <Text>{pricing.type}</Text>
              </Col>
              <Col>
                <Bold>Status</Bold>
                <Text>{pricing.status}</Text>
              </Col>
              <Col>
                <ActivatePricing
                  pricingID={pricing.id}
                  status={pricing.status}
                />
              </Col>
            </Row>
            <Row className="m-3">
              <Table>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Donation Service Fee</th>
                    <th>Delivery Fee</th>
                    <th>Shipping Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Individual Item ( {`<`} 45 lbs)</th>
                    <td>{displayPrice(pricing.individualItemPrice)}</td>
                    <td>{displayPrice(pricing.individualItemDeliveryPrice)}</td>
                    <td>{displayPrice(pricing.shippingPrice)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Box ( {`<`} 45 lbs)</th>
                    <td>{displayPrice(pricing.boxPrice)}</td>
                    <td>{displayPrice(pricing.boxDeliveryPrice)}</td>
                    <td> - </td>
                  </tr>
                  <tr>
                    <th scope="row">Large Item ( {`<`} 125 lbs)</th>
                    <td>{displayPrice(pricing.largeItemPrice)}</td>
                    <td>{displayPrice(pricing.largeItemDeliveryPrice)}</td>
                    <td> - </td>
                  </tr>
                  <tr>
                    <th scope="row">Oversized Item ( {`>`} 125 lbs) </th>
                    <td>{displayPrice(pricing.oversizedItemPrice)}</td>
                    <td>{displayPrice(pricing.oversizedItemDeliveryPrice)} </td>
                    <td> - </td>
                  </tr>
                  <tr>
                    <th scope="row">Standard Pallet (54&#34; or less)</th>
                    <td>{displayPrice(pricing.palletPrice)}</td>
                    <td>{displayPrice(pricing.palletDeliveryPrice)}</td>
                    <td> - </td>
                  </tr>
                  <tr>
                    <th scope="row">Large Pallet (55&#34; - 72&#34;)</th>
                    <td>{displayPrice(pricing.largePalletPrice)}</td>
                    <td>{displayPrice(pricing.largePalletDeliveryPrice)}</td>
                    <td> - </td>
                  </tr>
                  <tr>
                    <th scope="row">XL Pallet (73&#34; - 82&#34;)</th>
                    <td>{displayPrice(pricing.xlPalletPrice)}</td>
                    <td>{displayPrice(pricing.xlPalletDeliveryPrice)}</td>
                    <td> - </td>
                  </tr>
                  <tr>
                    <th scope="row">XXL Pallet (83&#34; - 90&#34;)</th>
                    <td>{displayPrice(pricing.xxlPalletPrice)}</td>
                    <td>{displayPrice(pricing.xxlPalletDeliveryPrice)}</td>
                    <td> - </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </CardBody>
        </Card>
      ))}
      {!loading && pricings?.length === 0 && (
        <Card style={{ background: "red" }}>
          <CardBody>
            <h4 style={{ color: "white", fontWeight: "bold" }}>
              {"Add pricing for this retailer"}
            </h4>
            <h5>
              {"Without pricing we won't be able to invoice this retailer."}
            </h5>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default Pricing;
