import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import SearchLocationInput from "../common/SearchLocationInput";
import useFormattedPhone from "../../..//src/helpers/hooks/useFormattedPhone.js";
import { formatAddress } from "../../..//src/helpers/address";
import styles from "../donate/donate.module.scss";
import { ChevronDown } from "react-feather";
import { toast } from "react-toastify";

const AddEditRetailer = ({ data, onSubmit }) => {
  const [values, setValues] = useState({});
  const [phone, setPhone] = useFormattedPhone(values.businessPhoneNumber || "");
  const [loading, setLoading] = useState(false);

  const setItem = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSetAddress = (value) => {
    let addr = {
      ...value,
      streetAddress2: values?.businessAddress?.streetAddress2,
    };
    setItem("businessAddress", addr);
  };

  const handleSetAddress2 = (e) => {
    let addr = {
      ...values?.businessAddress,
      streetAddress2: e.target.value,
    };
    setItem("businessAddress", addr);
  };

  useEffect(() => {
    setValues({
      name: data?.name,
      website: data?.website,
      description: data?.description,
      businessPhoneNumber: data?.businessPhoneNumber,
      businessAddress: data?.businessAddress,
      shopifyShopID: data?.shopifyShopID,
      billingType: data?.billingType || "payment_link",
      billingEmail: data?.billingEmail,
    });
  }, [data]);

  const onSubmitAddEdit = async () => {
    if (!values?.name) {
      toast.error("Name required");
      return;
    }

    if (!values?.billingEmail) {
      toast.error("Billing Email required");
      return;
    }

    if (!values?.billingType) {
      toast.error("Billing Type required");
      return;
    }

    if (
      !values?.businessAddress ||
      Object.keys(values?.businessAddress).length === 0
    ) {
      toast.error("Business Address required");
      return;
    }

    const onSuccessOrFailure = () => {
      setLoading(false);
    };
    setLoading(true);

    await onSubmit(values, onSuccessOrFailure);
  };

  const [dropdowns, setDropdowns] = useState({
    billingType: false,
  });

  return (
    <Card>
      <CardBody>
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Name<span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={(e) => setItem("name", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Business Address<span style={{ color: "red" }}>*</span>
                </Label>
                <SearchLocationInput
                  placeholdertext={""}
                  value={formatAddress(values.businessAddress)}
                  setAddress={handleSetAddress}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>
                  Billing Email<span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="billingEmail"
                  type="text"
                  value={values.billingEmail || ""}
                  onChange={(e) => setItem("billingEmail", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Address Street 2</Label>
                <Input
                  className="form-control"
                  type="text"
                  required={false}
                  name="addressLine2"
                  value={values?.businessAddress?.streetAddress2 || ""}
                  onChange={handleSetAddress2}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label>
                  Billing Type<span style={{ color: "red" }}>*</span>
                </Label>
                <Dropdown
                  required
                  isOpen={dropdowns.billingType}
                  toggle={(_) => {
                    setDropdowns((prev) => ({
                      ...prev,
                      billingType: !prev.billingType,
                    }));
                  }}
                >
                  <DropdownToggle
                    className={styles.dropdown}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <span style={{ paddingRight: "1em" }}>
                      {values.billingType || "None"}
                    </span>
                    <ChevronDown />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={(_) => {
                        setItem("billingType", "ach_debit");
                      }}
                    >
                      ACH Debit
                    </DropdownItem>
                    <DropdownItem
                      onClick={(_) => {
                        setItem("billingType", "card");
                      }}
                    >
                      Card
                    </DropdownItem>
                    <DropdownItem
                      onClick={(_) => {
                        setItem("billingType", "payment_link");
                      }}
                    >
                      Payment Link
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  name="description"
                  type="textarea"
                  value={values.description || ""}
                  onChange={(e) => setItem("description", e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Website</Label>
                <Input
                  name="website"
                  type="text"
                  value={values.website || ""}
                  onChange={(e) => setItem("website", e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Business Phone</Label>
                <Input
                  name="phone"
                  type="text"
                  value={phone || ""}
                  onChange={(e) => {
                    setItem("businessPhoneNumber", e.target.value);
                    setPhone(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Shopify Shop ID</Label>
                <Input
                  name="shopifyShopID"
                  type="text"
                  value={values.shopifyShopID || ""}
                  onChange={(e) => setItem("shopifyShopID", e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Button onClick={onSubmitAddEdit}>
                  {loading ? (
                    <div className="d-flex justify-content-around mt-4">
                      <Spinner />
                    </div>
                  ) : (
                    <span>Submit</span>
                  )}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default AddEditRetailer;
