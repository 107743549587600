import { gql } from "@apollo/client";
import React from "react";
import LoadingButton from "../common/loadingButton";
import { initializeApollo } from "src/helpers/apollo";
import { toast } from "react-toastify";
import { err_toast } from "src/helpers/errors";

const ADMIN_ACTIVATE_PRICING = gql`
    mutation adminActivatePricing($id: Int!) {
        adminActivatePricing(id: $id)
    }
`;

const ActivatePricing = ({ pricingID, status }) => {
  const client = initializeApollo();
  const activatePricing = () => {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: ADMIN_ACTIVATE_PRICING,
          variables: {
            id: pricingID,
          },
        })
        .then((_) => {
          setTimeout(() => {
            resolve("done");
            toast.success("Activated pricing");
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          reject(e);
          err_toast(e);
        });
    });
  };

  return (
    <>
      {status && status === "inactive" && (
        <LoadingButton
          text="Activate"
          color="primary"
          onClick={activatePricing}
        />
      )}
    </>
  );
};

export default ActivatePricing;
