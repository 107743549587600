import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import SearchLocationInput from "../common/SearchLocationInput";
import useFormattedPhone from "../../..//src/helpers/hooks/useFormattedPhone.js";
import { formatAddress } from "../../..//src/helpers/address";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, gql } from "@apollo/client";

const GET_CATEGORIES = gql`
  query getCategories {
    getCategories {
      uuid
      type
      name
      description
      subCategories {
        type
        uuid
        name
        description
        subCategories {
          type
          uuid
          name
          description
        }
      }
    }
  }
`;

const GET_REFERRAL_SOURCES = gql`
  query getReferralSources {
    getReferralSources {
      type
      label
    }
  }
`;

const GET_POPULATIONS_SERVED = gql`
  query getPopulationsServed {
    getPopulationsServed {
      type
      label
    }
  }
`;

const GET_FOCUS_AREAS = gql`
  query getFocusAreas {
    getFocusAreas {
      type
      label
    }
  }
`;
const establishmentTypes = [
  { title: "Nonprofit", value: "nonprofit" },
  { title: "Resale", value: "resale" },
  { title: "Food Bank", value: "food_bank" },
  { title: "School", value: "school" },
  { title: "Circularity Partner", value: "circularity_partner" },
];

const AddEditNonprofit = ({ data, onSubmit }) => {
  const ALL = "all";
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({});

  const [categoryTypes, setCategoryTypes] = useState([]);
  const { data: categoriesData, _ } = useQuery(GET_CATEGORIES);

  const [referralSource, setReferralSource] = useState();
  const { data: referralSourcesData, _1 } = useQuery(GET_REFERRAL_SOURCES);

  const [populationsServed, setPopulationsServed] = useState([]);
  const { data: populationsServedData, _2 } = useQuery(GET_POPULATIONS_SERVED);

  const [focusAreas, setFocusAreas] = useState([]);
  const { data: focusAreasData, _3 } = useQuery(GET_FOCUS_AREAS);

  const [phone, setPhone] = useFormattedPhone(values?.businessPhone || "");
  const [establishmentType, setEstablishmentType] = useState();

  const catSelected = (k) => categoryTypes.includes(k);
  const setCategory = (k) => (v) => {
    v
      ? setCategoryTypes((old) => [...old, k])
      : setCategoryTypes((old) => [...old.filter((kk) => kk !== k)]);
  };

  const findCategory = (t) => {
    for (let i = 0; i < categoriesData?.getCategories?.length; i += 1) {
      const level1 = categoriesData?.getCategories[i];
      if (level1.type === t) {
        return level1;
      }

      for (let j = 0; j < level1?.subCategories?.length; j += 1) {
        const level2 = level1.subCategories[j];
        if (level2.type === t) {
          return level2;
        }

        for (let k = 0; k < level2?.subCategories?.length; k += 1) {
          const level3 = level2.subCategories[k];
          if (level3.type === t) {
            return level3;
          }
        }
      }
    }
    return undefined;
  };

  const findParentCategory = (t) => {
    for (let i = 0; i < categoriesData?.getCategories?.length; i += 1) {
      const level1 = categoriesData?.getCategories[i];
      if (level1.type === t) {
        return undefined;
      }

      for (let j = 0; j < level1?.subCategories?.length; j += 1) {
        const level2 = level1.subCategories[j];
        if (level2.type === t) {
          return level1;
        }

        for (let k = 0; k < level2?.subCategories?.length; k += 1) {
          const level3 = level2.subCategories[k];
          if (level3.type === t) {
            return level2;
          }
        }
      }
    }
    return undefined;
  };

  const setTree = (k) => (v) => {
    [findCategory(k)].forEach((level1) =>
      level1?.subCategories?.forEach((level2) => {
        level2?.subCategories?.forEach((level3) => {
          setCategory(level3.type)(v);
          setTree(level3.type)(v);
        });
        setCategory(level2.type)(v);
        setTree(level2.type)(v);
      }),
    );
  };

  const setParents = (k) => (v) => {
    [findParentCategory(k)].forEach((level1) => {
      if (!level1) return;
      const allSelected = level1?.subCategories
        .map((level2) => level2.type)
        .filter((level2_type) => level2_type !== k)
        .reduce((a, level2_type) => a && catSelected(level2_type), v);

      setCategory(level1.type)(allSelected);
      setParents(level1.type)(allSelected);
    });
  };

  const setItem = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const setFocusAreasSelection = (e) => {
    if (e.target.checked) {
      setFocusAreas((prev) => [...prev, e.target.value]);
    } else {
      setFocusAreas((prev) => prev.filter((d) => d !== e.target.value));
    }
  };

  const setPopulationsServedSelection = (e) => {
    if (e.target.checked) {
      setPopulationsServed((prev) => [...prev, e.target.value]);
    } else {
      setPopulationsServed((prev) => prev.filter((d) => d !== e.target.value));
    }
  };

  const onChangeEstablishmentType = (e) => {
    const { value } = e.target;
    setEstablishmentType(value);
  };

  const handleChangeEvent = (e) => {
    const k = e.target.value;
    const v = !catSelected(k);

    if (v && k === ALL) {
      // Don't send some ['all', 'electronics'] to the backend
      setCategoryTypes((_) => []);
    }

    setCategory(k)(v);
    setTree(k)(v);
    setParents(k)(v);
  };

  const onSubmitAddEdit = async () => {
    if (!values?.organizationName) {
      toast.error("Name required");
      return;
    }

    if (!values?.address) {
      toast.error("Address required");
      return;
    }

    if (!values?.ein) {
      toast.error("EIN Required");
      return;
    }

    if (!values?.businessPhone) {
      toast.error("Business Phone Required");
      return;
    }

    if (!values?.website) {
      toast.error("Website required");
      return;
    }

    if (focusAreas.length == 0) {
      toast.error("Focus Areas required");
      return;
    }

    if (populationsServed.length == 0) {
      toast.error("Populations Served required");
      return;
    }

    if (categoryTypes.length === 0) {
      toast.error("Category types required");
      return;
    }

    if (!establishmentType) {
      toast.error("Establishment Type required");
      return;
    }

    const onSuccessOrFailure = () => {
      setLoading(false);
    };
    setLoading(true);

    let newValues = {
      ...values,
      focusAreas: focusAreas,
      populationsServed: populationsServed,
      establishmentType: establishmentType,
      categoryTypes: categoryTypes,
      referralSource: referralSource,
    };

    await onSubmit(newValues, onSuccessOrFailure);
  };

  useEffect(() => {
    setValues({
      organizationName: data?.organizationName,
      address: data?.address,
      ein: data?.ein,
      businessPhone: data?.businessPhone,
      website: data?.website,
      description: data?.description,
      mission: data?.mission,
      canReceivePallets: data?.canReceivePallets,
    });

    setCategoryTypes(data?.categoryTypes?.length ? data?.categoryTypes : []);
    setReferralSource(data?.referralSource);
    setPopulationsServed(data?.populationsServed || []);
    setFocusAreas(data?.focusAreas || []);
    setEstablishmentType(data?.establishmentType);
  }, [data]);

  return (
    <Card>
      <CardBody>
        <Form>
          <FormGroup row>
            <Label sm={2}>
              Name<span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={10}>
              <Input
                name="name"
                type="text"
                value={values.organizationName}
                onChange={(e) => setItem("organizationName", e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>
              Address<span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={10}>
              <SearchLocationInput
                placeholdertext={""}
                required={true}
                value={formatAddress(values.address)}
                setAddress={(input) => setItem("address", input)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>
              EIN<span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={10}>
              <Input
                name="ein"
                type="text"
                value={values.ein}
                onChange={(e) => setItem("ein", e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>
              Business Phone<span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={10}>
              <Input
                name="phone"
                type="tel"
                value={phone || ""}
                onChange={(e) => {
                  setItem("businessPhone", e.target.value);
                  setPhone(e.target.value);
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>
              Website<span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={10}>
              <Input
                name="website"
                type="text"
                value={values.website}
                onChange={(e) => setItem("website", e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>Description</Label>
            <Col sm={10}>
              <Input
                name="description"
                type="textarea"
                value={values.description}
                onChange={(e) => setItem("description", e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>Mission</Label>
            <Col sm={10}>
              <Input
                name="mission"
                type="textarea"
                value={values.mission}
                onChange={(e) => setItem("mission", e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>Referral source</Label>
            <Col sm={10}>
              <Input
                name="referralSource"
                type="select"
                value={referralSource}
                onChange={(e) => setReferralSource(e.target.value)}
              >
                <option value={null}> --- </option>
                {referralSourcesData?.getReferralSources?.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item.type}
                    >
                      {item.label}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>
              Focus areas<span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={10}>
              <Row className="flex align-items-start items-start">
                {focusAreasData?.getFocusAreas?.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      md={4}
                      xs={6}
                      className="mt-2"
                    >
                      <FormGroup
                        check
                        inline
                      >
                        <Input
                          name="focusAreas"
                          className="mb-0"
                          type="checkbox"
                          checked={focusAreas?.includes(item.type)}
                          onChange={(e) => setFocusAreasSelection(e)}
                          value={item.type}
                        />
                        <Label check>{item.label}</Label>
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </FormGroup>

          <hr />

          <FormGroup row>
            <Label sm={2}>
              Populations served<span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={10}>
              <Row className="flex align-items-start items-start">
                {populationsServedData?.getPopulationsServed?.map(
                  (item, index) => {
                    return (
                      <Col
                        key={index}
                        md={4}
                        xs={6}
                        className="mt-2"
                      >
                        <FormGroup
                          check
                          inline
                        >
                          <Input
                            name="populationsServed"
                            className="mb-0"
                            type="checkbox"
                            checked={populationsServed?.includes(item.type)}
                            onChange={(e) => setPopulationsServedSelection(e)}
                            value={item.type}
                          />
                          <Label check>{item.label}</Label>
                        </FormGroup>
                      </Col>
                    );
                  },
                )}
              </Row>
            </Col>
          </FormGroup>

          <hr />

          <FormGroup row>
            <Label sm={2}>
              Category types<span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={10}>
              <Row className="mt-5 flex align-items-start items-start">
                {categoriesData?.getCategories?.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      md={4}
                      xs={6}
                      className="mt-2"
                    >
                      <FormGroup
                        check
                        inline
                      >
                        <Input
                          name="categoryTypes"
                          className="mb-0"
                          type="checkbox"
                          checked={catSelected(item.type)}
                          onChange={(e) => handleChangeEvent(e)}
                          value={item.type}
                        />
                        <Label check>{item.name}</Label>
                      </FormGroup>
                      {item.subCategories &&
                        item.subCategories.map((d, i) => (
                          <Col key={i}>
                            <FormGroup
                              check
                              inline
                            >
                              <Input
                                name="categoryTypes"
                                className="mb-0"
                                type="checkbox"
                                checked={catSelected(d.type)}
                                onChange={(e) => handleChangeEvent(e)}
                                value={d.type}
                              />
                              <Label
                                check
                                style={{
                                  fontWeight: 400,
                                  fontSize: 13,
                                }}
                              >
                                {d.name}
                              </Label>
                            </FormGroup>
                            {d.subCategories &&
                              d.subCategories.map((dd, di) => (
                                <Col key={di}>
                                  <FormGroup
                                    check
                                    inline
                                  >
                                    <Input
                                      name="categoryTypes"
                                      className="mb-0"
                                      type="checkbox"
                                      checked={catSelected(dd.type)}
                                      onChange={(e) => handleChangeEvent(e)}
                                      value={dd.type}
                                    />
                                    <Label
                                      check
                                      style={{
                                        fontWeight: 400,
                                        fontSize: 13,
                                      }}
                                    >
                                      {dd.name}
                                    </Label>
                                  </FormGroup>
                                </Col>
                              ))}
                          </Col>
                        ))}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </FormGroup>

          <hr />

          <FormGroup row>
            <Label sm={2}>
              Establishment Type<span style={{ color: "red" }}>*</span>
            </Label>
            <Col
              sm={10}
              className="mt-2"
            >
              <Row>
                {establishmentTypes?.map(({ value, title }) => (
                  <Col
                    sm={6}
                    lg={2}
                    key={value}
                  >
                    <Input
                      name="establishmentType"
                      className="mb-0"
                      type="checkbox"
                      checked={establishmentType === value}
                      onChange={onChangeEstablishmentType}
                      value={value}
                    />
                    <Label check>{title}</Label>
                  </Col>
                ))}
              </Row>
            </Col>
          </FormGroup>

          <hr />

          <FormGroup row>
            <Label sm={2}>Can Receive Pallets</Label>
            <Col sm={{ size: 10 }}>
              <FormGroup check>
                <Input
                  checked={values.canReceivePallets}
                  onChange={(e) =>
                    setItem("canReceivePallets", e.target.checked)
                  }
                  type="checkbox"
                />
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup
            check
            row
          >
            <Col sm={{ offset: 2, size: 10 }}>
              <Button onClick={onSubmitAddEdit}>
                {loading ? (
                  <div className="d-flex justify-content-around mt-4">
                    <Spinner />
                  </div>
                ) : (
                  <span>Submit</span>
                )}
              </Button>
            </Col>
          </FormGroup>
        </Form>
        <ToastContainer />
      </CardBody>
    </Card>
  );
};

export default AddEditNonprofit;
