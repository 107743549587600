import { Card, CardHeader, CardBody, Col, Row, Input } from "reactstrap";
import { gql, useQuery } from "@apollo/client";
import retailersFormatter from "../../helpers/retailersFormatter";
import Datatable from "../common/datatable";
import React, { useState, useEffect } from "react";
import styles from "../donate/donate.module.scss";
import Text from "../common/text";

const GET_RETAILERS = gql`
  query ($input: GetRetailersInput!) {
    adminGetRetailers(input: $input) {
      total
      retailers {
        uuid
        name
        businessPhoneNumber
        description
        createdDate
      }
      hasMore
    }
  }
`;

const RetailerList = ({ title }) => {
  const [retailers, setRetailers] = useState([]);
  const [term, setTerm] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10);
  const [indexFrom, setIndexFrom] = useState(0);

  const { loading, error, data } = useQuery(GET_RETAILERS, {
    variables: {
      input: {
        term: term,
        indexFrom: indexFrom,
        limit: limit,
      },
    },
  });

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  const handleChangeTerm = (e) => {
    const value = e.target.value;
    setTerm(value);
  };

  useEffect(() => {
    if (!loading) {
      if (data && data.adminGetRetailers) {
        setRetailers(retailersFormatter(data.adminGetRetailers.retailers));
        setTotalRows(data.adminGetRetailers.total);
      } else {
        setRetailers([]);
      }
    }
  }, [loading]);

  return (
    <Card className={styles.donateItemEdit}>
      <CardHeader>
        <h3>{title}</h3>
        <Row>
          <Col
            sm="12"
            md="3"
          >
            <p className="mb-0 mt-2">Search term</p>
            <Input
              className="form-control"
              id="searchTerm"
              type="text"
              value={term}
              onChange={handleChangeTerm}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {error ? (
          <>Some Error Occured! Try Reloading the page.</>
        ) : retailers ? (
          <Datatable
            hideActions={true}
            myData={retailers}
            loading={loading}
            pageSize={limit}
            pagination={true}
            paginationServer={true}
            totalRows={totalRows}
            myClass="-striped -highlight"
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            noHeader
          />
        ) : (
          <Text>No retailers</Text>
        )}
      </CardBody>
    </Card>
  );
};

export default RetailerList;
