import React from "react";
import { truncate } from "../helpers/utils";
import moment from "moment";
import { Link } from "react-router-dom";

const retailersFormatter = (retailers) => {
  if (!retailers.length) {
    return;
  }

  const formattedRetailers = retailers.map((d) => {
    return {
      name: (
        <Link
          key={d.uuid}
          target="_blank"
          to={`./retailers/details/${d?.uuid}`}
        >
          {d.name}
        </Link>
      ),
      businessPhoneNumber: d.businessPhoneNumber,
      description: d.description ? truncate(d.description, 25) : null,
      created: moment(d.createdDate).format("DD MMM, YYYY hh:mm:SS A"),
    };
  });

  return formattedRetailers;
};

export default retailersFormatter;
