import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumb, Container } from "reactstrap";
import AddEditProduct from "../products/physical/AddEditProduct";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { gql, useMutation } from "@apollo/client";
import { err_toast } from "src/helpers/errors";
import { initializeApollo } from "src/helpers/apollo";
import { toast } from "react-toastify";

const APPROVE_ITEM_DONATION = gql`
    mutation ApproveItemDonation($data: ApproveItemDonationInput!) {
        approveItemDonation(input: $data) {
            itemDonation {
              uuid
            }
            product {
                uuid
                title
                slug
                description
                fairMarketValue {
                    value
                    currencyCode
                }
                isBundle
                autoMatch
                quantity
                quantityPalletsPerTruckload
                quantityBoxPerPallet
                quantityIndividualItemPerBox
                availableQuantity
                conditionTagTypes
                fulfillmentTypes
                location {
                    address {
                        streetAddress1
                        streetAddress2
                        streetAddress3
                        streetAddress4
                        city
                        state
                        zip
                        countryCode
                        latitude
                        longitude
                        timezone
                    }
                    contactNumber
                    notes
                }
            }
        }
    }
`;

const GET_ITEM_DONATION = gql`
  query adminGetItemDonation($input: String!) {
    adminGetItemDonation(uuid: $input) {
      sku
      productClass {
        uuid
      }
      title
      description
      status
      fairMarketValue {
        value
        currencyCode
      }
      isBundle
      autoMatch
      quantity
      quantityPalletsPerTruckload
      quantityBoxPerPallet
      quantityIndividualItemPerBox
      conditionTagTypes
      fulfillmentTypes
      recipientTypes
      unitType
      sizeType
      weightLbs
      lengthInches
      widthInches
      heightInches
      categoryTypes
      pickupHours {
        intervals {
          startTimeLocal
          endTimeLocal
          days
          date
        }
      }
      location {
        address {
          streetAddress1
          streetAddress2
          streetAddress3
          streetAddress4
          city
          state
          zip
          countryCode
          latitude
          longitude
        }
        contactNumber
        contactEmail
        notes
      }
      images {
        uuid
        alt
        displayOrder
        resolutions {
          m {
            url
          }
          xl {
            url
          }
        }
      }
      donor {
        user {
          firstName
          lastName
          email
          phoneNumber
          uuid

        }
        nonprofit {
          uuid
          organizationName
        }
        retailer {
          uuid
          name
        }
      }
      donorRetailer {
        uuid
        name
      }
      paymentIntentID
      total {
        value
        currencyCode
      }
      serviceFees {
        value
        currencyCode
      }
      totalDiscounts {
        value
        currencyCode
      }
      donationServiceFee {
        value
        currencyCode
      }
      fairMarketValue {
        value
        currencyCode
      }
      promoCode
    }
  }
`;

const EditDonation = ({ match }) => {
  const uuid = match.params.uuid;
  const history = useHistory();
  const [data, setData] = useState({});

  useEffect(() => {
    const client = initializeApollo();
    client
      .query({
        query: GET_ITEM_DONATION,
        variables: { input: uuid },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setData({
          ...res.data.adminGetItemDonation,
          status: "active",
          availableQuantity: res.data.adminGetItemDonation.quantity,
          recipientTypes: res.data.adminGetItemDonation.donorRetailer
            ? ["nonprofit"]
            : [],
          fulfillmentTypes:
            res.data.adminGetItemDonation?.fulfillmentTypes?.length > 0
              ? res.data.adminGetItemDonation?.fulfillmentTypes
              : ["pickup"],
          visibility: ["marketplace", "availability_email"],
        });
      });
  }, [uuid]);

  const [approveItemDonation] = useMutation(APPROVE_ITEM_DONATION);

  const onApproveItemDonation = (values, cb) => {
    const { nextPostingDate: _npd, productBundles: _pb, ...newValues } = values;

    const obj = {
      itemDonationUUID: uuid,
      item: newValues,
    };

    approveItemDonation({
      variables: {
        data: obj,
      },
    })
      .then((_) => {
        toast.success("Item donation edited and approved!");
        cb();
        setTimeout(() => {
          window.location.assign("/donate/list");
        }, 1000);
      })
      .catch((err) => {
        err_toast(err);
        cb();
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Edit Donation"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <AddEditProduct
          data={data}
          useRecentLocationInfo={false}
          onSubmit={onApproveItemDonation}
          onClose={() => history.push("/donate/list")}
          autoSuggestStockImage={false}
          showPrices={true}
          isDonation={true}
        />
      </Container>
    </Fragment>
  );
};

export default EditDonation;
