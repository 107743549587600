import {
  Home,
  Box,
  DollarSign,
  Users,
  ShoppingBag,
  Gift,
  LogOut,
  LifeBuoy,
  Zap,
  Book,
  Circle,
  FileText,
  PenTool,
  FolderPlus,
} from "react-feather";

export const MENUITEMS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Item Donations",
    icon: Gift,
    type: "link",
    path: "/donate/list",
    active: false,
  },
  {
    title: "Nonprofits",
    path: "/nonprofits",
    icon: LifeBuoy,
    type: "link",
    badgeType: "primary",
    active: false,
    children: [
      { title: "List", type: "link", path: "/nonprofits" },
      { title: "Create Nonprofit", type: "link", path: "/nonprofits/create" },
      // { title: "Wishlists", type: "link", path: "/wishlists" },
    ],
  },
  {
    title: "Circularity Partners",
    path: "/circularity-partners",
    icon: Circle,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Retailers",
    icon: ShoppingBag,
    type: "link",
    path: "/retailers",
    badgeType: "primary",
    active: false,
    children: [
      { title: "List", type: "link", path: "/retailers" },
      { title: "Create", type: "link", path: "/retailers/create" },
      { title: "Merge", type: "link", path: "/retailers/merge" },
    ],
  },
  {
    title: "Invoices",
    icon: Book,
    type: "link",
    path: "/invoices",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Donation Receipts",
    icon: PenTool,
    type: "link",
    path: "/donation-receipts",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Products",
    icon: Box,
    type: "link",
    path: "/products",
    active: false,
    children: [
      {
        path: "/products",
        title: "List",
        type: "link",
      },
      {
        path: "/products/add-product",
        title: "Add Product",
        type: "link",
      },
      {
        path: "/products/product-csv-upload",
        title: "Upload Product CSV",
        type: "link",
      },
    ],
  },
  // {
  //   title: "Delivery",
  //   icon: Truck,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/delivery/generate-quotes",
  //       title: "Quotes",
  //       type: "link",
  //     },
  //   ],
  // },
  {
    title: "Categories",
    path: "/categories",
    icon: FolderPlus,
    active: false,
    badgeType: "primary",
    type: "link",
  },
  {
    title: "Orders",
    path: "/orders",
    icon: DollarSign,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Matches",
    path: "/matches",
    icon: Zap,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Users",
    path: "/users/search",
    icon: Users,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "Blogs",
    icon: FileText,
    type: "sub",
    active: false,
    children: [
      {
        path: "/blogs",
        title: "List",
        type: "link",
      },
      {
        path: "/blog/add",
        title: "Add Blog",
        type: "link",
      },
    ],
  },
  // {
  //   title: "Coupons",
  //   icon: Tag,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/coupons/list-coupons", title: "List Coupons", type: "link" },
  //     {
  //       path: "/coupons/create-coupons",
  //       title: "Create Coupons",
  //       type: "link",
  //     },
  //   ],
  // },
  // {
  //   title: "Pages",
  //   icon: Clipboard,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/pages/list-page", title: "List Page", type: "link" },
  //     { path: "/pages/create-page", title: "Create Page", type: "link" },
  //   ],
  // },
  // {
  //   title: "Media",
  //   path: "/media",
  //   icon: Camera,
  //   type: "link",
  //   active: false,
  // },
  // {
  //   title: "Menus",
  //   icon: AlignLeft,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/menus/list-menu", title: "List Menu", type: "link" },
  //     { path: "/menus/create-menu", title: "Create Menu", type: "link" },
  //   ],
  // },
  // {
  //   title: "Users",
  //   icon: UserPlus,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/users/list-user", title: "User List", type: "link" },
  //     { path: "/users/create-user", title: "Create User", type: "link" },
  //   ],
  // },
  // {
  //   title: "Vendors",
  //   icon: Users,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     { path: "/vendors/list_vendors", title: "Vendor List", type: "link" },
  //     { path: "/vendors/create-vendors", title: "Create Vendor", type: "link" },
  //   ],
  // },
  // {
  //   title: "Localization",
  //   icon: Chrome,
  //   type: "sub",
  //   children: [
  //     {
  //       path: "/localization/transactions",
  //       title: "Translations",
  //       type: "link",
  //     },
  //     {
  //       path: "/localization/currency-rates",
  //       title: "Currency Rates",
  //       type: "link",
  //     },
  //     { path: "/localization/taxes", title: "Taxes", type: "link" },
  //   ],
  // },
  // {
  //   title: "Reports",
  //   path: "/reports/report",
  //   icon: BarChart,
  //   type: "link",
  //   active: false,
  // },
  // {
  //   title: "Settings",
  //   icon: Settings,
  //   type: "sub",
  //   children: [{ path: "/settings/profile", title: "Profile", type: "link" }],
  // },
  // {
  //   title: "Invoice",
  //   path: "/invoice",
  //   icon: Archive,
  //   type: "link",
  //   active: false,
  // },
  {
    title: "Logout",
    path: "#",
    id: "logout",
    icon: LogOut,
    type: "link",
    active: false,
  },
];
