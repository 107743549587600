import React, { Fragment, useMemo } from "react";
import { Container } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { gql, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { addCountryCode } from "../../helpers/utils";
import { initializeApollo } from "../../helpers/apollo";
import { toast } from "react-toastify";
import AddEditNonprofit from "./AddEditNonprofit";
import { err_toast } from "src/helpers/errors";

const GET_ADMIN_NONPROFIT = gql`
  query ($input: GetNonprofitInput!) {
    getAdminNonprofit(input: $input) {
      createdDate
      organizationName
      address {
        streetAddress1
        streetAddress2
        city
        state
        zip
        countryCode
        latitude
        longitude
      }
      ein
      categoryTypes
      businessPhone
      website
      users {
        uuid
        firstName
        lastName
        email
        phoneNumber
      }
      establishmentType
      populationsServed
      focusAreas
      referralSource
      description
      canReceivePallets
      mission
    }
  }
`;

const UPDATE_NONPROFIT = gql`
  mutation ($input: AdminEditNonprofitInput!) {
    adminEditNonprofit(input: $input) {
      organizationName
      address {
        streetAddress1
        streetAddress2
        streetAddress3
        streetAddress4
        city
        countryCode
        latitude
        longitude
        state
        zip
      }
      ein
      businessPhone
      website
      description
      mission
      referralSource
      focusAreas
      populationsServed
      canReceivePallets
      categoryTypes
      establishmentType
    }
  }
`;

const NonprofitEdit = () => {
  const { uuid } = useParams();
  const history = useHistory();

  const { loading, data } = useQuery(GET_ADMIN_NONPROFIT, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        uuid: uuid,
      },
    },
  });

  const nonprofit = useMemo(() => {
    return loading ? {} : data?.getAdminNonprofit;
  }, [loading]);

  let client = initializeApollo();

  const onSubmit = async (values, cb) => {
    client
      .mutate({
        mutation: UPDATE_NONPROFIT,
        fetchPolicy: "no-cache",
        variables: {
          input: {
            uuid: uuid,
            nonprofit: {
              organizationName: values.organizationName,
              address: values.address,
              ein: values.ein,
              businessPhone: addCountryCode(values.businessPhone),
              website: values.website,
              description: values.description || undefined,
              mission: values.mission || undefined,
              referralSource: values.referralSource || undefined,
              focusAreas: values.focusAreas || undefined,
              populationsServed: values.populationsServed || undefined,
              canReceivePallets: values.canReceivePallets || undefined,
              establishmentType: values.establishmentType || undefined,
            },
            categoryTypes: values.categoryTypes || undefined,
          },
        },
      })
      .then((_) => {
        toast.success("Nonprofit updated successfully!");
        setTimeout(() => {
          history.push("/nonprofit/" + uuid);
          cb();
        }, 1000);
      })
      .catch((err) => {
        err_toast(err);
        cb();
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        title="Edit Nonprofit"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <AddEditNonprofit
          data={nonprofit}
          onSubmit={onSubmit}
        />
      </Container>
    </Fragment>
  );
};

export default NonprofitEdit;
