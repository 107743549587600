import React from "react";
import { money_to_str, truncate } from "../helpers/utils";
import moment from "moment";
import { Link } from "react-router-dom";

const mapTypeToBadge = {
  service_fees: "badge-secondary",
  shipping_fees: "badge-warning",
  delivery_fees: "badge-warning",
  donation_service_fees: "badge-success",
};

const transactionsFormatter = (invoices) => {
  if (!invoices?.length) {
    return;
  }

  const formattedTransactions = invoices.map((d) => {
    return {
      created: moment(d.createdAt).format("DD MMM, YYYY hh:mm:SS A"),
      Amount: money_to_str(d.amount),
      type: (
        <span
          key={d.uuid}
          className={`badge ${mapTypeToBadge[d.type]}`}
        >
          {d.type}
        </span>
      ),
      description: d?.description,
      Retailer: (
        <Link
          key={d.retailer.uuid}
          target="_blank"
          to={`/retailers/details/${d.retailer.uuid}`}
        >
          {d.retailer.name} &nbsp;&nbsp;
        </Link>
      ),
      Invoice: d?.invoice ? (
        <Link
          key={d.retailer.uuid}
          target="_blank"
          to={`/invoice/${d.invoice.uuid}`}
        >
          {truncate(d.invoice.uuid, 8)} &nbsp;&nbsp;
        </Link>
      ) : (
        "-"
      ),
      Match: d?.match ? (
        <Link
          key={d.match.uuid}
          target="_blank"
          to={`/match/${d.match.uuid}`}
        >
          {truncate(d.match.uuid, 8)} &nbsp;&nbsp;
        </Link>
      ) : (
        "-"
      ),
      Fulfillment: d?.fulfillment ? (
        <Link
          key={d.fulfillment.uuid}
          target="_blank"
          to={`/fulfillment/${d.fulfillment.uuid}`}
        >
          {truncate(d.fulfillment.uuid, 8)} &nbsp;&nbsp;
        </Link>
      ) : (
        "-"
      ),
      Item: d?.item ? (
        <Link
          key={d.item.uuid}
          target="_blank"
          to={`/product/${d.item.product.uuid}`}
        >
          {d.item.product.title} &nbsp;&nbsp;
        </Link>
      ) : (
        "-"
      ),
      Pricing: d?.pricing ? (
        <Link
          key={d.retailer.uuid}
          target="_blank"
          to={`/retailers/details/${d.retailer.uuid}`}
        >
          {moment(d.pricing.createdDate).format("YYYY-MM-DD HH:mm:SS")}{" "}
          &nbsp;&nbsp;
        </Link>
      ) : (
        "-"
      ),
    };
  });

  return formattedTransactions;
};

export default transactionsFormatter;
