import { gql } from "@apollo/client";
import React, { useState } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Button,
} from "reactstrap";
import LoadingButton from "../common/loadingButton";
import { initializeApollo } from "src/helpers/apollo";
import { num_to_money } from "src/helpers/utils";
import { toast } from "react-toastify";
import { err_toast } from "src/helpers/errors";

const ADMIN_ADD_PRICING = gql`
    mutation adminAddPricing($input: PricingInput!) {
        adminAddPricing(input: $input) {
            id
            type
            status
            createdDate
            individualItemPrice {
                value
                currencyCode
            }
            largeItemPrice {
                value
                currencyCode
            }
            boxPrice {
                value
                currencyCode
            }
            oversizedItemPrice{
              value
              currencyCode
            }
            palletPrice {
                value
                currencyCode
            }
            largePalletPrice {
              value
              currencyCode
            }
            xlPalletPrice {
              value
              currencyCode
            }
            xxlPalletPrice {
              value
              currencyCode
            }
            shippingPrice {
                value
                currencyCode
            }
            individualItemDeliveryPrice {
              value
              currencyCode
            }
            boxDeliveryPrice {
              value
              currencyCode
            }
            largeItemDeliveryPrice {
              value
              currencyCode
            }
            oversizedItemDeliveryPrice {
              value
              currencyCode
            }
            palletDeliveryPrice {
              value
              currencyCode
            }
            largePalletDeliveryPrice {
              value
              currencyCode
            }
            xlPalletDeliveryPrice {
              value
              currencyCode
            }
            xxlPalletDeliveryPrice {
              value
              currencyCode
            }
        }
    }
`;

const AddPricing = ({ retailer }) => {
  const [open, setOpen] = useState(false);
  const [individualItemPrice, setIndividualItemPrice] = useState(4.0);
  const [boxPrice, setBoxPrice] = useState(15.0);
  const [largeItemPrice, setLargeItemPrice] = useState(36.0);
  const [oversizedItemPrice, setOversizedItemPrice] = useState(45.0);
  const [palletPrice, setPalletPrice] = useState(50.0);
  const [largePalletPrice, setLargePalletPrice] = useState(55.0);
  const [xlPalletPrice, setXlPalletPrice] = useState(65.0);
  const [xxlPalletPrice, setXxlPalletPrice] = useState(75.0);

  const [individualItemShippingPrice, setIndividualItemShippingPrice] =
    useState(0);

  const [individualItemDeliveryPrice, setIndividualItemDeliveryPrice] =
    useState(15.0);
  const [boxDeliveryPrice, setBoxDeliveryPrice] = useState(15.0);
  const [largeItemDeliveryPrice, setLargeItemDeliveryPrice] = useState(50.0);
  const [oversizedItemDeliveryPrice, setOversizedItemDeliveryPrice] =
    useState(65.0);
  const [palletDeliveryPrice, setPalletDeliveryPrice] = useState(70.0);
  const [largePalletDeliveryPrice, setLargePalletDeliveryPrice] =
    useState(80.0);
  const [xlPalletDeliveryPrice, setXlPalletDeliveryPrice] = useState(90.0);
  const [xxlPalletDeliveryPrice, setXxlPalletDeliveryPrice] = useState(100.0);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  let client = initializeApollo();

  const addPricing = () => {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: ADMIN_ADD_PRICING,
          variables: {
            input: {
              retailerUUID: retailer?.uuid,
              type: "metered",
              individualItemPrice: num_to_money(individualItemPrice, "USD"),
              largeItemPrice: num_to_money(largeItemPrice, "USD"),
              oversizedItemPrice: num_to_money(oversizedItemPrice, "USD"),
              boxPrice: num_to_money(boxPrice, "USD"),
              palletPrice: num_to_money(palletPrice, "USD"),
              largePalletPrice: num_to_money(largePalletPrice, "USD"),
              xlPalletPrice: num_to_money(xlPalletPrice, "USD"),
              xxlPalletPrice: num_to_money(xxlPalletPrice, "USD"),

              shippingPrice: num_to_money(individualItemShippingPrice, "USD"),

              individualItemDeliveryPrice: num_to_money(
                individualItemDeliveryPrice,
                "USD",
              ),
              boxDeliveryPrice: num_to_money(boxDeliveryPrice, "USD"),
              largeItemDeliveryPrice: num_to_money(
                largeItemDeliveryPrice,
                "USD",
              ),
              oversizedItemDeliveryPrice: num_to_money(
                oversizedItemDeliveryPrice,
                "USD",
              ),
              palletDeliveryPrice: num_to_money(palletDeliveryPrice, "USD"),
              largePalletDeliveryPrice: num_to_money(
                largePalletDeliveryPrice,
                "USD",
              ),
              xlPalletDeliveryPrice: num_to_money(xlPalletDeliveryPrice, "USD"),
              xxlPalletDeliveryPrice: num_to_money(
                xxlPalletDeliveryPrice,
                "USD",
              ),
            },
          },
        })
        .then((_) => {
          setTimeout(() => {
            resolve("done");
            toast.success("Added new pricing");
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          reject(e);
          err_toast(e);
        });

      setTimeout(() => resolve("done"), 2000);
    });
  };

  return (
    <div className="d-flex justify-content-end mb-2">
      <Button
        color="primary"
        onClick={onOpenModal}
      >
        Add New Pricing
      </Button>
      <Modal
        isOpen={open}
        toggle={onCloseModal}
        size="lg"
      >
        <ModalHeader>
          <span>Add New Pricing</span>
        </ModalHeader>
        <ModalBody>
          <span>All prices in USD</span>
          <Table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Donation Service Fee</th>
                <th>Delivery Fee</th>
                <th>Shipping Fee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Individual Item (upto 45 lbs)</th>
                <td>
                  <Input
                    name="individualItemPrice"
                    type="number"
                    value={individualItemPrice}
                    onChange={(e) => setIndividualItemPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td>
                  <Input
                    name="individualItemDeliveryPrice"
                    type="number"
                    value={individualItemDeliveryPrice}
                    onChange={(e) =>
                      setIndividualItemDeliveryPrice(e.target.value)
                    }
                    bsSize="sm"
                  />
                </td>
                <td>
                  <Input
                    name="shippingPrice"
                    type="number"
                    value={individualItemShippingPrice}
                    onChange={(e) =>
                      setIndividualItemShippingPrice(e.target.value)
                    }
                    bsSize="sm"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Box (upto 45 lbs)</th>
                <td>
                  <Input
                    name="boxPrice"
                    type="number"
                    value={boxPrice}
                    onChange={(e) => setBoxPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td>
                  <Input
                    name="boxDeliveryPrice"
                    type="number"
                    value={boxDeliveryPrice}
                    onChange={(e) => setBoxDeliveryPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td> - </td>
              </tr>
              <tr>
                <th scope="row">Large Item (upto 125 lbs)</th>
                <td>
                  <Input
                    name="largeItemPrice"
                    type="number"
                    value={largeItemPrice}
                    onChange={(e) => setLargeItemPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td>
                  <Input
                    name="largeItemDeliveryPrice"
                    type="number"
                    value={largeItemDeliveryPrice}
                    onChange={(e) => setLargeItemDeliveryPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td> - </td>
              </tr>
              <tr>
                <th scope="row">Oversized Item (over 125 lbs) </th>
                <td>
                  <Input
                    name="oversizedItemPrice"
                    type="number"
                    value={oversizedItemPrice}
                    onChange={(e) => setOversizedItemPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td>
                  <Input
                    name="oversizedtemDeliveryPrice"
                    type="number"
                    value={oversizedItemDeliveryPrice}
                    onChange={(e) =>
                      setOversizedItemDeliveryPrice(e.target.value)
                    }
                    bsSize="sm"
                  />
                </td>
                <td> - </td>
              </tr>
              <tr>
                <th scope="row">Standard Pallet (54&#34;)</th>
                <td>
                  <Input
                    name="palletPrice"
                    type="number"
                    value={palletPrice}
                    onChange={(e) => setPalletPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td>
                  <Input
                    name="palletDeliveryPrice"
                    type="number"
                    value={palletDeliveryPrice}
                    onChange={(e) => setPalletDeliveryPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td> - </td>
              </tr>
              <tr>
                <th scope="row">Large Pallet (55&#34; - 72&#34;)</th>
                <td>
                  <Input
                    name="largePalletPrice"
                    type="number"
                    value={largePalletPrice}
                    onChange={(e) => setLargePalletPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td>
                  <Input
                    name="largePalletDeliveryPrice"
                    type="number"
                    value={largePalletDeliveryPrice}
                    onChange={(e) =>
                      setLargePalletDeliveryPrice(e.target.value)
                    }
                    bsSize="sm"
                  />
                </td>
                <td> - </td>
              </tr>
              <tr>
                <th scope="row">XL Pallet (73&#34; - 82&#34;)</th>
                <td>
                  <Input
                    name="xlPalletPrice"
                    type="number"
                    value={xlPalletPrice}
                    onChange={(e) => setXlPalletPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td>
                  <Input
                    name="xlPalletDeliveryPrice"
                    type="number"
                    value={xlPalletDeliveryPrice}
                    onChange={(e) => setXlPalletDeliveryPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td> - </td>
              </tr>
              <tr>
                <th scope="row">XXL Pallet (83&#34; - 90&#34;)</th>
                <td>
                  <Input
                    name="xxlPalletPrice"
                    type="number"
                    value={xxlPalletPrice}
                    onChange={(e) => setXxlPalletPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td>
                  <Input
                    name="xxlPalletDeliveryPrice"
                    type="number"
                    value={xxlPalletDeliveryPrice}
                    onChange={(e) => setXxlPalletDeliveryPrice(e.target.value)}
                    bsSize="sm"
                  />
                </td>
                <td> - </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            text="Save"
            onClick={addPricing}
            color="primary"
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default AddPricing;
